<template>
  <div>
    <v-row
      class="mb-2">
      <v-col
        sm="6"
        cols="12"
        class="ml-auto d-flex align-center"
      >
         <v-text-field
          @keyup.enter="handleBusinessSearch"
          v-model="businessSearch"
          label="Search"
          single-line
          hide-details
          class="mt-0 pt-0">
          </v-text-field>
         <v-btn
            icon
            @click="handleBusinessSearch">
            <v-icon>mdi-magnify</v-icon>
            </v-btn>
      </v-col>
    </v-row>
    <v-card :class="{ 'card-table_container--mobile' : $vuetify.breakpoint.xsOnly }">
      <v-data-table
        :headers="headers"
        :items="businesses"
        :server-items-length="pagination.itemsLength"
        :options.sync="pagination"
        :loading="loading"
        :footer-props="{'items-per-page-options':[10, 50, 100]}"
        @update:options="tablePaginated"
      >
        <template v-slot:item="{ item }">
          <tr v-if="$vuetify.breakpoint.smAndUp"
            @click="viewBusinessDetail($event, item)">
            <td>{{ item.name }}</td>
            <td>{{ item.owner }}</td>
            <td>
              {{ formattedAddress(item.street, item.city, item.county, item.state, item.country, item.zip_code) }}
            </td>
            <td>
              {{ item.email }}<br/>
              {{ item.owner_contact_number }}
            </td>
            <td>{{ item.assigned_to_user }}</td>
            <td>
              <div v-if="getUserInfo(item)" class="text-right">
                <v-btn class="mx-2" dark small color="primary"> Edit
                </v-btn>
              </div>
            </td>
          </tr>
          <v-card
            v-else
            class="my-2 mx-1"
            @click="viewBusinessDetail($event, item)"
          >

            <v-card-title class="pt-2">{{ item.name }}</v-card-title>
            <v-card-subtitle class="pb-2">{{ item.owner }}<template v-if="item.co_owner">, {{ item.co_owner }}</template></v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text class="py-0">
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>
                      mdi-map-marker-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ formattedAddress(item.street, item.city, item.county, item.state, item.country, item.zip_code) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.owner_contact_number">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-phone
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.owner_contact_number }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.email">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-email
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.email }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item  v-if="item.created_by_user">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-face-agent
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.assigned_to_user }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="text-right">
              <v-btn class="ml-auto" dark color="primary"> Edit </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';

  export default {
    name: 'BusinessDirectoryList',
    data() {
      return {
        search: '',
        pagination: {
          page: 1,
          itemsLength: 0,
          itemsPerPage: 10
        },
        headers: [
          {text: 'Business name', value: 'name', sortable: false},
          {text: 'Owner name', value: 'owner', sortable: false},
          {text: 'Address', value: 'address', sortable: false},
          {text: 'Contacts', value: 'contact', sortable: false},
          {text: 'Staff', value: 'created_by_user', sortable: false},
          {text: '', value: 'action', sortable: false}
        ],
        businessSearch: "",
        businessEntries: [],
        isBusinessLoading: false,
        searchClicked: false,
        loading: false,
        businesses: []
      }
    },
    computed: {
      user(){
        return this.$store.getters.user;
      }
    },
    methods: {
      ...mapActions(['getBusinesses', 'deletegetBusiness']),
      getBusinessList(params) {
        this.loading = true
        this.getBusinesses({
          ordering: 'name',
          search: this.businessSearch,
          limit: params.limit,
          offset: params.offset
        })
        .then((response) => {
          this.businesses = response.data.results
          this.pagination.itemsLength = response.data.count
          this.loading = false
        });
      },
      tablePaginated(event) {
        this.getBusinessList({search: this.businessSearch, limit: event.itemsPerPage, offset: ((event.page - 1)*event.itemsPerPage)})
      },
      deleteBusiness() {

      },
      viewBusinessDetail(event, item) {
        if(this.user.is_superuser || this.user.id == item.assigned_to){
          this.$router.push(`edit/${item.id}`)
        }
      },
      handleBusinessSearch() {
        this.searchClicked = !this.searchClicked
        this.pagination.page = 1
        this.getBusinessList({offset:0, limit: 10})
      },
      formattedAddress(street = '', city = '', county = '', state = '', country = '', zip_code = '') {
        return [street, city, county, state, country, zip_code].filter(Boolean).join(", ");
      },
      getUserInfo(item) {
        return this.user.is_superuser || this.user.id == item.assigned_to ? true : false
      }
    },
    watch: {
      businessSearch(newValue, oldValue) {
        if(oldValue !== '' && newValue == '' && this.searchClicked) {
          this.handleBusinessSearch()
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/assets/styles/variables.scss';
  tr {
    cursor: pointer;
  }
  ::v-deep {
    .v-data-table-header th {
      text-transform: uppercase;
    }
    tbody td a {
      color: inherit;
      &:hover {
        font-weight: bold;
        color: $primary-color;
      }
    }
    td {

      min-width: 150px;
      @media only screen and (max-device-width: 768px) {
        padding: 0 8px!important
      }
    }
  }
</style>
